/* @flow */

import * as React from 'react'
import { DemonstrationFormTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props

  return (
    <DemonstrationFormTemplate
      url={location.href}
      state={location.state}
      title="Demander une démo"
      name="Wino Démo"
      description="Optez pour Wino, un logiciel de caisse tout-en-un pour centraliser vos activités"
    />
  )
}
